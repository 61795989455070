import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { Link } from 'react-router-dom';
import "../css/team.css";

function Mitwohungen() {
  const { data, fetchData, fetchBildUrl, bilderUrls } = useContext(DataContext);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [WohnungenMembers, setWohnungenMembers] = useState([]);

  useEffect(() => {
    fetchData('Wohnungen');
    fetchBildUrl('Wohnungen-background-image');
  }, [fetchData, fetchBildUrl]);

  useEffect(() => {
    setBackgroundImageUrl(bilderUrls['Wohnungen-background-image']);
    const WohnungenData = data['Wohnungen'] || [];
    const sortedWohnungenData = WohnungenData.sort((a, b) => a.sortid - b.sortid); // Sortierung basierend auf sortid
    const memberNames = sortedWohnungenData.filter(item => item.bereich === 'Wohnungen' && item.text.startsWith('Objekt-Name'));
  
    const members = memberNames.map((member, index) => {
      const name = member.inhalt;
      const rolesKey = `Objekt-Sichpunkte-${index + 1}`;
      const roles = sortedWohnungenData.filter(roleItem => roleItem.bereich === 'Wohnungen' && roleItem.text.startsWith(rolesKey)).map(role => role.inhalt.trim());
      const availabilityKey = `Objekt-ver-${index + 1}`;
      const availability = sortedWohnungenData.find(availItem => availItem.bereich === 'Wohnungen' && availItem.text === availabilityKey)?.inhalt;
      const imageKey = `Wohnungen-image${index + 1}`;
      fetchBildUrl(imageKey); // Stellen Sie sicher, dass das Bild für jedes WohnungenObjekt geladen wird
  
      return { name, roles, availability, imageKey };
    });

    setWohnungenMembers(members);
  }, [data, bilderUrls, fetchBildUrl]);

  return (
    <section className="section-5 wf-section">
      <div className="div-block">
  {/* Hintergrundbild als normales <img> Element */}
  {backgroundImageUrl && (
    <img src={backgroundImageUrl} alt="Hintergrund" className="background-image" />
  )}
      <div className="div-block-4">
        <ol className="list-2 w-list-unstyled">
          <li><h1 className="heading">Unsere Mietobjekte</h1></li>
          {WohnungenMembers.map((member, index) => (
            <li key={index}>
              <div className="div-block-2">
                <div className="w-layout-layout">
                  <div className="w-layout-cell">
                    {/* Objekterbild als normales <img> Element anzeigen */}
                    {bilderUrls[member.imageKey] && (
                      <img src={bilderUrls[member.imageKey]} alt={`Objekt ${index + 1}`} className="Wohnungen-member-image" />
                    )}
                  </div>
                  <div className="div-block-102">
                    <div className="w-layout-cell"><div className="text-block-7">{member.name}</div></div>
                    <div className="w-layout-cell">
                      <ul className="list-3">
                        {member.roles.map((role, roleIndex) => <li key={roleIndex}><div>{role}</div></li>)}
                      </ul>
                    </div>
                    <div className="w-layout-cell"><div className="text-block-11">{member.availability}</div></div>
                    <div className="w-layout-cell">
                    <Link to={`/objekt/${encodeURIComponent(member.name)}`} className="button-2">Anfrage</Link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
      </div>
    </section>
  );
}

export default Mitwohungen;