import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { useParams } from 'react-router-dom';
import '../css/GoogleFormsIframe.css';

function GoogleFormsIframe() {
  const { objektName } = useParams();
  const { data, fetchData } = useContext(DataContext);
  const [iframeUrl, setIframeUrl] = useState('');

  // Lade die Daten für alle Objekte beim ersten Rendern der Komponente
  useEffect(() => {
    fetchData('Objekt');
  }, [fetchData]);
  
  // Nachdem die Daten geladen wurden, suche das spezifische Objekt basierend auf dem objektName
  useEffect(() => {
    if (data['Objekt'] && Array.isArray(data['Objekt'])) {
      // Logge die Daten, um sicherzustellen, dass die Struktur erwartungsgemäß ist
      console.log('Objektdaten:', data['Objekt']);
  
      const objektData = data['Objekt'].find(obj => obj.text === objektName); // Entferne `encodeURIComponent` für den Test
  
      if (objektData) {
        setIframeUrl(objektData.inhalt);
      } else {
        console.log('Spezifisches Objekt nicht gefunden:', objektName);
      }
    } else {
      console.log('Daten für Bereich Objekt sind nicht verfügbar oder kein Array', data['Objekt']);
    }
  }, [data, objektName]);
  return (
    <div>
      {iframeUrl ? (
        // Zeige den iFrame nur an, wenn eine URL verfügbar ist
        <iframe
          src={iframeUrl}
          width="100%"
          height="100%"
          style={{ minHeight: '100vh' }}
          frameBorder="0"
          title="Google Form"
        >
          Laden...
        </iframe>
      ) : (
        // Anzeige einer Lademeldung, während die URL noch nicht verfügbar ist
        <p>iFrame wird geladen...</p>
      )}
    </div>
  );
}

export default GoogleFormsIframe;

