import React, { useState } from "react";
import "../css/navbar.css";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1390) {
      setShowMenu(false);
    }
  };

  return (
      <nav className="nav">
      <a href="/">
          <img src="/images/Logo.png" alt="Logo" />
        </a>

        <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            <div className="nav__item">
            <Link to="/header" className="nav__link" onClick={closeMenuOnMobile}>
              Dienstleistungen
              </Link>
            </div>
            <div className="nav__item">
              <Link to="/team" className="nav__link" onClick={closeMenuOnMobile}>
                Mitarbeiter
                </Link>
                </div> 
                <div className="nav__item">
              <Link to="/contact" className="nav__link" onClick={closeMenuOnMobile}>
                Kontakt
                </Link>
                </div> 
                <div className="nav__item">
              <Link to="/mitwohnungen" className="nav__link" onClick={closeMenuOnMobile}>
                Mietinteresse
                </Link>
                </div>
                <div className="nav__item">
                  <Link to="/impressum" className="nav__link" onClick={closeMenuOnMobile}>
                    Impressum
                    </Link>
                    </div>
            <div className="nav__item">            
            <div className="nav__cta">
              <a href="mailto:iho@sijelly.it" className="nav__cta-link">Email: immo.holthey@gmail.com</a>
              <a href="tel:015779200456" className="nav__cta-link">Tel.: 0160/4108080</a>
              </div>
              </div>
              <div className="nav__item">
              <a href="https://www.google.de/maps/place/Hinter+d.+Schule+2/@53.6052329,10.2211651,20.52z/data=!4m7!3m6!1s0x47b1f5277095518f:0x4e900f05307181ff!4b1!8m2!3d53.6052426!4d10.2212532!16s%2Fg%2F11b8y7ffcp?entry=ttu" className=" nav__cta">
              Hinter der Schule 2<br />22145 Stapelfeld
              </a>
              </div>
              </ul>
              <button className="nav__close" id="nav-close" onClick={toggleMenu}>
               &#x2715; {/* Emoji für das Schließen-Symbol (rotes X) */}
               </button>
               </div>
               <button className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                &#x2630; {/* Emoji für das Menü-Symbol (Hamburger Icon) */}
                </button>
                </nav>
  );
};

export default Navbar;